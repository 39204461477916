import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import PreferToTalk from "../components/prefer-to-talk";
import ShortlistPropertyCard from "../components/shorlist-property-card";
import CartContext from "../context/CartContext";
import ContactFormHome from "../components/contact-form-home";
import AreaGuideCardLarge from "../components/area-guide-card-large";
import AreaGuideCardSmall from "../components/area-guide-card-small";
import ExpertCard from "../components/expert-card";

const AreaGuidesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			allWpAreaGuide {
				nodes {
					title
					slug
					areaGuideFields {
						thumbnailImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						popular
						image1 {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						description
					}
				}
			}
			popularAreas: allWpAreaGuide(
				filter: { areaGuideFields: { popular: { eq: true } } }
				limit: 3
			) {
				nodes {
					title
					slug
					areaGuideFields {
						thumbnailImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						popular
						image1 {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						description
					}
				}
			}
			heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const { cart } = React.useContext(CartContext);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Area Guides",
				item: {
					url: `${siteUrl}/area-guides`,
					id: `${siteUrl}/area-guides`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Area Guides | Flex Office Solutions"
				description="Find out more about some of our favourite areas."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/area-guides`,
					title: "Area Guides | Flex Office Solutions",
					description: "Find out more about some of our favourite areas.",
					images: [
						{
							url: `${data.heroInterior?.localFile.publicURL}`,
							width: `${data.heroInterior?.localFile.childImageSharp.original.width}`,
							height: `${data.heroInterior?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroInterior?.altText}`,
						},
					],
				}}
			/>
			<div style={{ overflow: "hidden" }}>
				<section className="bg-primary bg-opacity-10 py-4 py-lg-5 mb-xl-5 mb-4">
					<Container>
						<Row>
							<Col className="text-center">
								<p className="text-med-grey fw-bold text-uppercase">
									Find out more about some of our favourite areas
								</p>
								<h1>Area guides</h1>
							</Col>
						</Row>
					</Container>
				</section>
				<section className=" pb-lg-5 ">
					<Container className=" ">
						<Row className="py-lg-5 py-4">
							<Col className="">
								<h2 className="fs-1">Popular areas</h2>
								<p className="">
									Explore a selection of the most frequently searched and
									sought-after areas when considering London office space for
									your business needs.
								</p>
							</Col>
						</Row>{" "}
						<Row className="gy-4 d-none d-lg-flex">
							{data.popularAreas.nodes.map((area, i) => (
								<AreaGuideCardLarge
									img={
										area.areaGuideFields.thumbnailImage?.localFile
											?.childImageSharp?.gatsbyImageData ||
										area.areaGuideFields.image1?.localFile?.childImageSharp
											?.gatsbyImageData ||
										"false"
									}
									imgAlt={area.areaGuideFields.image1.altText}
									description={area.areaGuideFields.description}
									title={area.title}
									link={`/area-guides/${area.slug}`}
									id={area.slug}
								/>
							))}
						</Row>
						<Row className="gy-4  d-lg-none">
							{data.popularAreas.nodes.map((area, i) => (
								<AreaGuideCardSmall
									img={
										area.areaGuideFields.thumbnailImage?.localFile
											?.childImageSharp?.gatsbyImageData ||
										area.areaGuideFields.image1?.localFile?.childImageSharp
											?.gatsbyImageData ||
										"false"
									}
									imgAlt={area.areaGuideFields.image1?.altText}
									description={area.areaGuideFields.description}
									title={area.title}
									link={`/area-guides/${area.slug}`}
									id={`${area.slug}-mobile`}
								/>
							))}
						</Row>
					</Container>
				</section>
				<section className=" py-lg-5 ">
					<Container className=" ">
						<Row className="py-lg-5 py-4">
							<Col className="text-center">
								<h2 className="fs-1">All area guides</h2>
								<p className="">
									Are you currently contemplating the ideal location for
									establishing your business? Our extensive knowledge of
									London's office market is at your disposal. Each area is
									distinctive and caters to specific industries. Use our
									comprehensive London area guides to gain insight into which
									locale suits you and your team best. And, as ever, don't
									hesitate to reach out to us to initiate your office quest.
								</p>
							</Col>
						</Row>{" "}
						<Row className="gy-4">
							{data.allWpAreaGuide.nodes.map((area, i) => (
								<AreaGuideCardSmall
									img={
										area.areaGuideFields.thumbnailImage?.localFile
											?.childImageSharp?.gatsbyImageData ||
										area.areaGuideFields.image1?.localFile?.childImageSharp
											?.gatsbyImageData ||
										"false"
									}
									imgAlt={area.areaGuideFields.image1?.altText}
									description={area.areaGuideFields.description}
									title={area.title}
									link={`/area-guides/${area.slug}`}
									id={`${area.slug}-small`}
								/>
							))}
						</Row>
					</Container>
				</section>
				<section>
					<Container className="my-lg-6 my-3 my-md-5">
						<div
							style={{
								boxShadow: "0px 1px 20px #00000029",
								borderRadius: "12px",
							}}
							className="py-3 py-md-5 px-3 px-md-5 mb-3 bg-white  bg-opacity-10 "
						>
							<Row>
								<Col>
									<h3>Get in touch</h3>
								</Col>
							</Row>
							<Row>
								<Col lg={4}>
									<p>
										Fill in your contact details below and we'll put you in
										touch with one of our experts
									</p>
								</Col>
							</Row>
							<ContactFormHome />
						</div>
						<ExpertCard />
					</Container>
				</section>
			</div>
		</Layout>
	);
};

export default AreaGuidesPage;
