import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Button, Col } from "react-bootstrap";
import gsap from "gsap";
import SafeHtmlParser from "./safeHtmlParser"; // Adjust the path as necessary

const AreaGuideCardSmall = ({ img, imgAlt, description, title, link, id }) => {
	// Function to get trimmed HTML content
	const getTrimmedDescription = (htmlContent, maxLength) => {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = htmlContent;
		let textContent = tempElement.textContent || tempElement.innerText || "";

		if (textContent.length > maxLength) {
			textContent = textContent.substr(0, maxLength);
			textContent =
				textContent.substr(
					0,
					Math.min(textContent.length, textContent.lastIndexOf(" "))
				) + "...";
		}

		return textContent;
	};

	// Set the max length to 170 characters
	const trimmedDescription =
		typeof window !== "undefined"
			? getTrimmedDescription(description, 170)
			: description;

	const enterAnimation = (btn, desc) => {
		gsap.to(desc, { opacity: 1, duration: 0.5 });
		gsap.to(btn, { maxHeight: "20rem", duration: 0.5 });
	};

	const exitAnimation = (btn, desc) => {
		gsap.to(desc, { opacity: 0, duration: 0.5 });
		gsap.to(btn, { maxHeight: "6rem", duration: 0.5 });
	};

	return (
		<Col
			onMouseEnter={() => enterAnimation(`.${id}`, `.${id}-desc`)}
			onMouseLeave={() => exitAnimation(`.${id}`, `.${id}-desc`)}
			md={6}
			lg={4}
			xl={3}
		>
			<Link to={link} className="text-decoration-none">
				<div
					style={{ borderRadius: "16px", overflow: "hidden" }}
					className="position-relative"
				>
					<div
						style={{
							zIndex: 2,
							maxHeight: "6rem",
							backgroundColor: "rgba(0, 0, 0, 0.65)",
							overflow: "hidden",
						}}
						className={`position-absolute w-100 text-white bottom-0 start-0 ${id}`}
					>
						<div style={{ height: "20rem" }} className="py-3">
							<h2 className="gramond-bold px-4 pb-2 fs-3">{title}</h2>
							<div
								style={{ height: "4px", opacity: 0 }}
								className={`w-90 mb-3 bg-primary ${id}-desc`}
							></div>
							<p
								style={{ fontSize: "100%", opacity: 0 }}
								className={`${id}-desc text-center px-4`}
							>
								<SafeHtmlParser htmlContent={trimmedDescription} />
							</p>
							<div
								style={{ opacity: 0 }}
								className={`text-center pt-2 px-4 ${id}-desc`}
							>
								<Button
									style={{ borderRadius: "200px" }}
									variant="primary"
									className="text-white"
								>
									Discover more
								</Button>
							</div>
						</div>
					</div>
					{img !== "false" && (
						<GatsbyImage
							style={{ height: "20rem" }}
							className="w-100"
							image={img}
							alt={imgAlt}
						/>
					)}
					{img === "false" && (
						<div
							style={{ height: "20rem" }}
							className="w-100 bg-primary bg-opacity-10"
						></div>
					)}
				</div>
			</Link>
		</Col>
	);
};

export default AreaGuideCardSmall;
